import conquerImage1 from '../assets/7.jpg';
import conquerImage2 from '../assets/8.jpg';

import {Grid, Box, Typography, Divider} from "@mui/material";
import {Container} from 'react-bootstrap';
import {SectionDivider} from './common/section-divider';
import {BoxItem} from "./BoxItem";

export const HomeCards = () => {
    return (
        <Container style={{marginTop: 100, textAlign: "center", color: '#fff'}}>
            <Typography sx={{mt: 5, mb: 5}} variant={'h4'} fontWeight={'bold'}>
             Welcome to History Conquer!
            </Typography>

          
            <Typography variant={'h5'}>Game Features</Typography>
            <Grid container spacing={2}>
                <Grid item lg={3}>
                    <BoxItem text={'Classic Version 5165'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'No Ninja/Monk'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'Pure Classic'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'Custom Events'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'Balanced PVP'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'Duel Bots'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'No CPS'} />
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'Anti Cheat'} />
                </Grid>
            </Grid>
            <Grid container spacing={4} p={5} sx={{ color: "rgb(57, 171, 111)" }}>
               
               
            </Grid>
        </Container>
    )
}